import React from 'react';
import { Button } from '@mui/material';
import paypalLogo from './paypal-logo.png'; // Make sure you have the PayPal logo in your project
import { useLanguage } from './LanguageContext';

export default function PaypalMeButton() {
    // Replace with your actual PayPal.me link
    const { language } = useLanguage();


    const handleClick = () => {
        const currentLocal = language === 'ar' ? 'ar_SA' : 'en_US'
        const paypalLink = `https://paypal.me/ClassDay?locale.x=${currentLocal}`;
        window.open(paypalLink, '_blank', 'noopener,noreferrer');
    };

    return (
        <Button
            variant="contained"
            color="primary"
            onClick={handleClick}
            sx={{ borderRadius: 2, padding: '10px 20px' }}
        >
          <img src={paypalLogo} width={20} height={20} alt="PayPal" />&nbsp;
            PayPal Me
        </Button>
    );
}
