import React from "react";
import { motion } from "framer-motion";
import { Container, Typography, Button, Box } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useNavigate } from "react-router-dom";
import { useLanguage } from './LanguageContext';

const RegistrationSuccess = () => {
  const navigate = useNavigate();
  const { language } = useLanguage();

  return (
    <Container
      maxWidth="sm"
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        borderRadius: 4,
        padding: 4,
        marginTop:10,
        boxShadow: 3,
      }}
    >
      <motion.div
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
      >
        <CheckCircleOutlineIcon sx={{ fontSize: 80, color: "#4caf50", mb: 2 }} />
        <Typography variant="h4" sx={{ fontWeight: "bold", mb: 1 }}>
          {language === 'ar' ? 'تم التسجيل بنجاح' : 'Registration Successful!'}
        </Typography>
        <Typography variant="body1" sx={{ color: "#555", mb: 3 }}>
          {language === 'ar' ? 'لقد تم إنشاء حسابك بنجاح. يمكنك الآن تسجيل الدخول والبدء في استخدام المنصة.' : 'Your account has been created successfully. You can now log in and start using the platform.'}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          sx={{ mt: 2, px: 4, py: 1, fontSize: 16 }}
          onClick={() => navigate("../")}
        >
          {language === 'ar' ? 'الرئيسية' : 'Home'}
        </Button>
      </motion.div>
    </Container>
  );
};

export default RegistrationSuccess;
