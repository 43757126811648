import React, { useEffect ,useState,useCallback } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { Container, Typography, Grid, Button, Card, CardContent, CardMedia, Box ,Link } from '@mui/material';
import keywordsData from './keywords.json';
import { Helmet } from 'react-helmet';
import { useLanguage } from './LanguageContext';
import { useTheme } from './ThemeContext';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import SlowMotionVideoIcon from '@mui/icons-material/SlowMotionVideo';
import MovieIcon from '@mui/icons-material/Movie';
import { motion } from "framer-motion";
import { styled } from "@mui/system";
import  AdComponent  from './AdComponent';

const HomePage = ({ lang }) => {
    const { language } = useLanguage();
    const { theme } = useTheme();
    const [isMobile, setIsMobile] = useState(false);
    const [showAds, setShowAds] = useState(false);
    const [shareData, setShareData] = useState([]);
    const [showExtirnalAds, setShowExtirnalAds] = useState(false);
    const [showExtirnalBopAds, setShowExtirnalBopAds] = useState(false);
    const isLight = theme === 'light';
    const heroSecBackgroundColor = isLight ? '#333' : '#fff';
    const heroImage = `https://samrt-loader.com/social_icons/unnamed-${language === "ar" ? "right" : "left"}.webp`;
    const navigate = useNavigate(); // Initialize useNavigate
    const MokeUpBackGround = isLight ? "linear-gradient(135deg, #FF416C, #FF4B2B)"  : 'linear-gradient(to right,rgb(10, 10, 10),rgb(51, 45, 43))';
    const heroBackGround = isLight ? 'linear-gradient(to right, #FF6B81, #FFA07A)' : 'linear-gradient(to right,rgb(10, 10, 10),rgb(51, 45, 43))';
    const HeroSection = styled(Box)(({ theme }) => ({
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "50px 5%",
      background: heroBackGround,
      color: "white",
      borderRadius: "8px",
      boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column-reverse",
        textAlign: "center",
        padding: "40px 5%",
      },
    }));
    
    const HeroTextContainer = styled(Box)(({ theme }) => ({
      flex: 1,
      textAlign: language === "ar" ? "right" : "left",
      padding: "0 20px",
      [theme.breakpoints.down("md")]: {
        textAlign: "center",
        padding: "0",
      },
    }));
    
    const HeroImageContainer = styled(Box)(({ theme }) => ({
      flex: 1,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        marginBottom: "20px",
      },
    }));

    const handleReqStieData = useCallback(async () => {
        try {
          const token = localStorage.getItem('authToken') || ""
          const response = await fetch('https://samrt-loader.com/kydwon/api/siteInfo' , {
            method: 'GET', // Specify the method
            headers: {
              'X-Authorization':`Bearer ${token}`
            },
          });
          const data = await response.json();
          if (data.success) {
            //setVideoData(response.data.files); // Set videoData state with response data
            setIsMobile(data.is_mobile);
            setShareData(data.share_data);
            if(data.is_mobile){
              localStorage.setItem('isMobile','true')
            } else {
              localStorage.setItem('isMobile','false')
            }
            
            var isShowAds = data.is_show_ads;
            if(isShowAds) {
              setTimeout(() => setShowAds(true), 3000); // Check again after 3 seconds
            } else {
              setShowAds(false);
            }

          var isShowExtirnalAds = data.is_show_external_ads;
          if(isShowExtirnalAds) {
            setShowExtirnalAds(true)
          } else {
            setShowExtirnalAds(false);
          }

          var isShowExtirnalBopAds = data.is_show_external_bop_ads;
          if(isShowExtirnalBopAds) {
            setShowExtirnalBopAds(true)
          } else {
            setShowExtirnalBopAds(false);
          }

            localStorage.setItem('share_count',data.share_count)
          }
        } catch (error) {
        }
      }, []); // Remove shareData from the dependencies

      useEffect(() => {
        if(lang != null){
          localStorage.setItem('language', lang);
        }
        const checkIsLogin = localStorage.getItem('isLogin');
        if (checkIsLogin === "true") {
          // Optionally decode token to get user info
          navigate(`../editer`);
        } else {
            handleReqStieData();
        }
    },[handleReqStieData]);

    const handelOpenDownloader = () => {
        navigate(`../${language}/downloads`);
    }

    const handelOpenEditer = () => {
        navigate(`../editer`);
    }

    const SEO = ({ title, description, keywords }) => (
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
          <meta name="keywords" content={keywords} />
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "WebSite",
                "name": "${title}",
                "url": "https://samrt-loader.com"
              }
            `}
          </script>
        </Helmet>
      );
  

 // Animation variants for fade in and hover effect.
 const fadeInUp = {
  initial: { opacity: 0, y: 50 },
  animate: { opacity: 1, y: 0, transition: { duration: 1.4 } },
};

const fadeInUpMokUp = {
  initial: { opacity: 0, y: 50 },
  animate: { opacity: 1, y: 0, transition: { duration: 0.4 } },
};

const cardHover = { scale: 1.03 };

const bodeBack = theme === 'light' ? "linear-gradient(135deg,rgb(248, 250, 245) 0%,rgb(241, 239, 236) 100%)" : "linear-gradient(135deg,rgb(11, 11, 11) 0%,rgb(16, 15, 15) 100%)"
return (
  <div
    style={{
      minHeight: "100vh",
      padding: "3rem 0",
      background: bodeBack,
    }}
  >
    <Container maxWidth="lg">
      <SEO
        title={language === "ar" ? keywordsData.title_ar : keywordsData.title_en}
        description={
          language === "ar" ? keywordsData.description_ar : keywordsData.description_en
        }
        keywords={keywordsData.keywords.join(", ")}
      />

{/* Hero Section */}
<HeroSection>
  <HeroTextContainer>
    <Box 
      sx={{ 
        display: "flex", 
        flexDirection: { xs: "column", md: "row" }, 
        alignItems: "center", 
        justifyContent: "space-between",
        textAlign: { xs: "center", md: "left" },
        gap: 3
      }}
    >
      {/* Left Section: Text & Buttons */}
      <motion.div
        initial="initial"
        animate="animate"
        variants={fadeInUp}
        style={{ maxWidth: 600 }}
      >
        <Typography
          variant={isMobile ? "h4" : "h3"}
          sx={{ fontWeight: "bold", mb: 2, lineHeight: 1.2 }}
        >
          {language === "ar"
            ? "تحميل وتعديل مقاطع فيديو قصيرة ومذهلة لوسائل التواصل"
            : "Download & Create Stunning Social Media Videos & Shorts"}
        </Typography>
        <Typography
          variant="h6"
          sx={{ fontWeight: 300, color: "#ccc", maxWidth: 600, mb: 3 }}
        >
          {language === "ar"
            ? "محرر سهل الاستخدام لإنشاء محتوى عالي الجودة في دقائق."
            : "Easy-to-use editor for creating high-quality content in minutes."}
        </Typography>

        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 2 }}>
          <Button
            variant="contained"
            onClick={handelOpenDownloader}
            sx={{
              background: `linear-gradient(135deg, ${language === "ar" ? "#FF4B2B, #FF416C" : "#FF416C, #FF4B2B"})`,
              color: "white",
              fontSize: "1.1rem",
              fontWeight: "bold",
              padding: "12px 24px",
              borderRadius: "8px",
              textTransform: "none",
              transition: "0.3s",
              "&:hover": {
                background: `linear-gradient(135deg, ${language === "ar" ? "#FF416C, #FF4B2B" : "#FF4B2B, #FF416C"})`,
                transform: "scale(1.05)",
              },
            }}
          >
            {language === "ar" ? "ابدأ تحميل وتعديل الفيديوهات" : "Start Downloading & Edit Videos"}
          </Button>  

          <Link 
            href="https://play.google.com/store/apps/details?id=com.smart.loader"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="../../google_play.svg" width={180} height={110} alt="Google Play Logo" />  
          </Link>
        </Box>
      </motion.div>
    </Box>
  </HeroTextContainer>

  {/* Right Section: App Preview with Circular Background */}
  <HeroImageContainer>
          {/* Left Section: Text & Buttons */}
          <motion.div
        initial="initial"
        animate="animate"
        variants={fadeInUpMokUp}
        style={{ maxWidth: 600 }}
      >
    <Box 
      sx={{
        width: isMobile ? '100%' : 420, // Adjust size
        height: isMobile ? 320 : 420,
        background: MokeUpBackGround, // Circular gradient background
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)"
      }}
    >
      <img 
        src={heroImage} 
        alt="App Preview" 
        style={{ 
          maxWidth: "90%", 
          height: "auto", 
          borderRadius: "20px" 
        }} 
      />
    </Box>
    </motion.div>
  </HeroImageContainer>
</HeroSection>


{showExtirnalAds && (
        <div>
            <AdComponent/>
        </div>
        )}

      {/* Features Section */}
      <Grid
  container
  spacing={4}
  justifyContent="center"
  sx={{
    mt: 8,
    px: { xs: 2, md: 0 },
    ...(language === 'ar' && isMobile ? { mr: -15 } : {mr: -32}),
  }}
  style={{marginRight:language === 'ar' && isMobile ? -15 : -32}}
>
        {/* Feature Card: Download Videos */}
        <Grid item xs={12} sm={6} md={4}>
          <motion.div whileHover={cardHover} initial="initial" animate="animate" variants={fadeInUp}>
            <Card sx={{ maxWidth: 345, mx: "auto", borderRadius: 3, boxShadow: 3 }}>
              <Box sx={{ height: 160, display: "flex", alignItems: "center", justifyContent: "center", bgcolor: "#ffe0e0" }}>
                <DownloadForOfflineIcon sx={{ fontSize: 64, color: "#ff7b7b" }} />
              </Box>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {language === "ar" ? "أداة تحميل الفيديوهات" : "Download Videos Tool"}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {language === "ar"
                    ? "قم بتنزيل وتعديل الفيديوهات من السوشيال ميديا مثل فيس بوك أو إنستجرام أو سناب شات أو تيك توك أو كاواي أو يوتيوب أو تويتر وغيرها بتنسيق HD."
                    : "Download & edit videos from social media like Facebook, Instagram, Snapchat, TikTok, Kwai, YouTube, Twitter and more in HD format."}
                </Typography>
                <Button
                  onClick={handelOpenDownloader}
                  aria-label="get started video downloader"
                  variant="contained"
                  color="secondary"
                  sx={{ mt: 2 }}
                >
                  {language === "ar" ? "تحميل الفيديوهات" : "Download Now"}
                </Button>
              </CardContent>
            </Card>
          </motion.div>
        </Grid>

        {/* Feature Card: Edit Videos */}
        <Grid item xs={12} sm={6} md={4}>
          <motion.div whileHover={cardHover} initial="initial" animate="animate" variants={fadeInUp}>
            <Card sx={{ maxWidth: 345, mx: "auto", borderRadius: 3, boxShadow: 3 }}>
              <Box sx={{ height: 160, display: "flex", alignItems: "center", justifyContent: "center", bgcolor: "#e0f7fa" }}>
                <MovieIcon sx={{ fontSize: 64, color: "#ff7b7b" }} />
              </Box>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {language === "ar" ? "تعديل الفيديوهات" : "Edit Videos"}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {language === "ar"
                    ? "قم بتعزيز مقاطع الفيديو باستخدام أدوات متطورة مثل القص، اللصق، الفلترة، وإضافة نصوص."
                    : "Enhance your videos with advanced tools like cropping, trimming, filtering, and text overlay."}
                </Typography>
                <Button
                  onClick={handelOpenEditer}
                  aria-label="get started editer"
                  variant="contained"
                  color="secondary"
                  sx={{ mt: 2 }}
                >
                  {language === "ar" ? "بدء الإستخدام" : "Get Started"}
                </Button>
              </CardContent>
            </Card>
          </motion.div>
        </Grid>

        {/* Feature Card: Create Content */}
        <Grid item xs={12} sm={6} md={4}>
          <motion.div whileHover={cardHover} initial="initial" animate="animate" variants={fadeInUp}>
            <Card sx={{ maxWidth: 345, mx: "auto", borderRadius: 3, boxShadow: 3 }}>
              <Box sx={{ height: 160, display: "flex", alignItems: "center", justifyContent: "center", bgcolor: "#e8eaf6" }}>
                <SlowMotionVideoIcon sx={{ fontSize: 64, color: "#ff7b7b" }} />
              </Box>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  {language === "ar" ? "إنشاء محتوى" : "Create Content"}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {language === "ar"
                    ? "صمم وأنشئ مقاطع فيديو قصيرة وجذابة لتعزيز حضورك على وسائل التواصل الاجتماعي."
                    : "Design and create engaging short-form videos to boost your social media presence."}
                </Typography>
                <Button
                  onClick={handelOpenEditer}
                  aria-label="get started editer"
                  variant="contained"
                  color="secondary"
                  sx={{ mt: 2 }}
                >
                  {language === "ar" ? "بدء إنشاء محتوى" : "Start Creating"}
                </Button>
              </CardContent>
            </Card>
          </motion.div>
        </Grid>
      </Grid>

      {/* Social Links Section */}
      <Box sx={{ width: "100%", mt: 10 }}>
        <Grid container spacing={2} justifyContent="center">
          {[
            { href: `./${language}/tiktok`, label: language === "ar" ? "تيك توك" : "TikTok" },
            { href: `./${language}/snapchat`, label: language === "ar" ? "سناب شات" : "Snapchat" },
            { href: `./${language}/instagram`, label: language === "ar" ? "إنستاجرام" : "Instagram" },
            { href: `./${language}/youtube`, label: language === "ar" ? "يوتيوب" : "YouTube" },
            { href: `./${language}/twitter`, label: language === "ar" ? "تويتر" : "Twitter" },
            { href: `./${language}/kwai`, label: language === "ar" ? "كاواي" : "Kwai" },
            { href: `./${language}/facebook`, label: language === "ar" ? "فيس بوك" : "Facebook" },
            { href: `./${language}/threads`, label: language === "ar" ? "ثريدز" : "Threads" },
            { href: `./${language}/pinterest`, label: language === "ar" ? "بينتريست" : "Pinterest" },
            { href: `./${language}/download-videos`, label: language === "ar" ? "غيرها من المواقع" : "Others" },
          ].map((link, index) => (
            <Grid item key={index}>
              <Link href={link.href} sx={{ px: 1.5, py: 0.5, color: "#1976d2", fontWeight: 500, textDecoration: "none", "&:hover": { textDecoration: "underline" } }}>
                {link.label}
              </Link>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Social Icons Section */}
      <Box sx={{ width: "100%", mt: 10 }}>
        <Grid container spacing={2} justifyContent="center">
          {[
            { src: "https://samrt-loader.com/social_icons/facebook.png", alt: "facebook" },
            { src: "https://samrt-loader.com/social_icons/instagram.png", alt: "instagram" },
            { src: "https://samrt-loader.com/social_icons/threads.png", alt: "threads" },
            { src: "https://samrt-loader.com/social_icons/tiktok.png", alt: "tiktok" },
            { src: "https://samrt-loader.com/social_icons/snapchat.png", alt: "snapchat" },
            { src: "https://samrt-loader.com/social_icons/twitter.png", alt: "twitter" },
            { src: "https://samrt-loader.com/social_icons/pinterest.png", alt: "pinterest" },
            { src: "https://samrt-loader.com/social_icons/kwai.webp", alt: "kwai" },
            { src: "https://samrt-loader.com/social_icons/youtube.png", alt: "youtube" },
          ].map((icon, index) => (
            <Grid item key={index}>
              <Card sx={{ maxWidth: 50, maxHeight: 100, boxShadow: 1, borderRadius: 2 }}>
                <CardMedia
                  component="img"
                  height="50"
                  image={icon.src}
                  alt={icon.alt}
                  sx={{ objectFit: "contain", p: 1 }}
                />
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Language Toggle */}
      <Box sx={{ width: "100%", mt: 6, mb: 4 }}>
        <Grid container spacing={2} justifyContent="center">
          <Grid item>
            <Link href="./ar" sx={{ px: 2, py: 1, color: "#1976d2", fontWeight: 500, textDecoration: "none", "&:hover": { textDecoration: "underline" } }}>
              {language === "ar" ? "عربي" : "عربي"}
            </Link>
          </Grid>
          <Grid item>
            <Link href="./en" sx={{ px: 2, py: 1, color: "#1976d2", fontWeight: 500, textDecoration: "none", "&:hover": { textDecoration: "underline" } }}>
              {language === "ar" ? "English" : "English"}
            </Link>
          </Grid>
        </Grid>
      </Box>
    </Container>
  </div>
);
};

export default HomePage;
