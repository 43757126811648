// App.js

import React,{ useEffect } from 'react';
import Navbar from './components/Navbar';
import HomePage from './components/HomePage';
import RegistrationSuccess from './components/RegistrationSuccess';
import DownloadsPage from './components/DownloadsSoctial';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider as MuiThemeProvider, createTheme } from '@mui/material/styles';
import { LoadingProvider } from './components/LoadingContext';
import LoadingDialog from './components/LoadingDialog';
import BackgroundReplacer from './components/BackgroundReplacer';
import { useTheme } from './components/ThemeContext';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import rtlPlugin from 'stylis-plugin-rtl';
import { Link, Box } from '@mui/material';
import { prefixer } from 'stylis';
import { LanguageProvider, useLanguage } from './components/LanguageContext';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import NotFound from './components/NotFound'; // Import your NotFound component
import VideoGallery from './components/VideoGallery';
import PaymentSuccess from './components/PaymentSuccess';
import TextsGallery from './components/TextsGallery';
import PayPalPaymentPage from './components/PayPalPaymentPage';
import ContactUs from './components/ContactUs';
import ShareBox from './components/ShareBox';
import CustumVideoEditer from './components/CustumVideoEditer';
import ImagesGallery from './components/ImagesGallery';
import Privacy from './components/Privacy';
import Tearms from './components/Tearms';
import CookieConsent from 'react-cookie-consent';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const App = () => (
  <LanguageProvider>
    <MainApp />
  </LanguageProvider>
);

const MainApp = () => {
  const { language } = useLanguage();
  const { theme } = useTheme();
  const muiTheme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: theme,
          primary: {
            main: theme === 'light' ? '#feb47b' : '#fff',
          },
          secondary: {
            main: theme === 'light' ? '#FF6B81' : '#ccc',
          },
        },
        direction: language === 'ar' ? 'rtl' : 'ltr',
      }),
    [theme,language]
  );
  const cacheRtl = createCache({
    key: language === 'ar' ? 'muirtl' : 'mui',
    stylisPlugins: language === 'ar' ? [prefixer, rtlPlugin] : [],
  });

  useEffect(() => {
    document.body.style.backgroundColor = theme === 'light' ? '#f6f3f3' : '#121212';
  }, [theme]);
  const acceptAllText = language === 'ar' ? 'قبول الكل' : 'Accept All';
  return (
    <CacheProvider value={cacheRtl}>
    <MuiThemeProvider theme={muiTheme}>
    <LoadingProvider>
    <LoadingDialog />
    <div dir={language === 'ar' ? 'rtl' : 'ltr'}>
          <CssBaseline />
          <Navbar />
          {/* Other components of your app */}
          <CookieConsent
            location="bottom"
            buttonText= {acceptAllText}
            cookieName="myCookieConsent"
            style={{ background: "#2B373B" }}
            buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
            expires={150} >
              {language === 'ar' ? 'تساعد ملفات تعريف الارتباط في تقديم خدماتنا وتحسين تجربة التصفح لديك وتقديم محتوى مخصص وتحليل حركة المرور. بالنقر على "قبول الكل"، فإنك توافق على استخدامنا' : 'Cookies help deliver our services, improve your browsing experience, serve personalized content, and analyze traffic.By clicking "Accept All", you consent to our use of'}{" "}
              <a href="../../Privacy" style={{ color: "#c22554" }}>
                {language === 'ar' ? 'لمعرف المزيد في سياسة الخصوصية الخاصة بنا.' : 'Learn more in our Privacy Policy.'}
              </a>
            </CookieConsent>
              <BrowserRouter>
                <Routes>
                  <Route path="/" element={<HomePage/>} />
                  <Route path="/registsuccess" element={<RegistrationSuccess/>} />
                  <Route path="/ar" element={<HomePage lang="ar" />} />
                  <Route path="/en" element={<HomePage lang="en" />} />
                  <Route path="/downloads" element={<DownloadsPage />} />
                  <Route path="/ar/downloads" element={<DownloadsPage lang="ar" />} />
                  <Route path="/en/downloads" element={<DownloadsPage lang="en" />} />
                  <Route path="/TextsGallery" element={<TextsGallery />} />
                  <Route path="/ImagesGallery" element={<ImagesGallery />} />
                  <Route path="/VideoGallery" element={<VideoGallery />} />
                  <Route path="/Privacy" element={<Privacy />} />
                  <Route path="/Tearms" element={<Tearms />} />
                  <Route path="/ContactUs" element={<ContactUs />} />
                  <Route path="/ShareBox" element={<ShareBox />} />
                  <Route path="/editer" element={<CustumVideoEditer />} />
                  <Route path="/background-replacer" element={<BackgroundReplacer />} />
                  <Route path="/payments" element={<PayPalPaymentPage />} />
                  <Route path="/successPayment" element={<PaymentSuccess />} />
                  <Route path="/ar/snapchat" element={<DownloadsPage lang="ar" Type="snap" />} />
                  <Route path="/en/snapchat" element={<DownloadsPage lang="en" Type="snap"/>} />
                  <Route path="/ar/tiktok" element={<DownloadsPage lang="ar" Type="tiktok" />} />
                  <Route path="/en/tiktok" element={<DownloadsPage lang="en" Type="tiktok"/>} />
                  <Route path="/ar/facebook" element={<DownloadsPage lang="ar" Type="facebook" />} />
                  <Route path="/en/facebook" element={<DownloadsPage lang="en" Type="facebook" />} />
                  <Route path="/ar/youtube" element={<DownloadsPage lang="ar" Type="youtube"/>} />
                  <Route path="/en/youtube" element={<DownloadsPage lang="en" Type="youtube"/>} />
                  <Route path="/ar/kwai" element={<DownloadsPage lang="ar" Type="kwai"/>} />
                  <Route path="/en/kwai" element={<DownloadsPage lang="en" Type="kwai"/>} />
                  <Route path="/ar/instagram" element={<DownloadsPage lang="ar" Type="instagram"/>} />
                  <Route path="/en/instagram" element={<DownloadsPage lang="en" Type="instagram"/>} />
                  <Route path="/ar/pinterest" element={<DownloadsPage lang="ar" Type="pinterest"/>} />
                  <Route path="/en/pinterest" element={<DownloadsPage lang="en" Type="pinterest"/>} />
                  <Route path="/ar/threads" element={<DownloadsPage lang="ar" Type="threads"/>} />
                  <Route path="/en/threads" element={<DownloadsPage lang="en" Type="threads"/>} />
                  <Route path="/ar/twitter" element={<DownloadsPage lang="ar" Type="twitter"/>} />
                  <Route path="/en/twitter" element={<DownloadsPage lang="en" Type="twitter"/>} />
                  <Route path="/ar/download-videos" element={<DownloadsPage lang="ar" />} />
                  <Route path="/en/download-videos" element={<DownloadsPage lang="en" />} />
                  <Route path="*" element={<NotFound />} /> {/* Catch-all route */}
                </Routes>
              </BrowserRouter>
              <Box width="100%" textAlign="center"  my={3}>
              <Box width="100%" textAlign="center"  my={8}>
              <div className="app-store-button-container">
        <Link href="#" onClick={() => window.open('https://play.google.com/store/apps/details?id=com.smart.loader', '_blank')} color="inherit" className="animated-link">
            <img src="../../google_play.svg" width={250} height={150} alt="Google Play Logo" />  
        </Link>    
      </div>
      <Box width="100%"  my={5}>
        <div className="homepage-footer">
          <p>
             {language === 'ar' ? 'استمتع بالفيديو الذي تم تنزيله عن طريق سمارت لودر 2025 ! يمكنك استخدامه للاستخدام الشخصي أو مشاركته مع أصدقائك.' : 'Enjoy your downloaded video By Smart Loader 2025 ! You can use it for personal use or share it with your friends.'}
          </p>
          <Link href="../../Privacy" color="inherit" className="animated-link">
             {language === 'ar' ? 'سياسة الخصوصية' : 'Privacy Police'}
          </Link>
          &nbsp;&nbsp;
          <Link href="../../Tearms" color="inherit" className="animated-link">
             {language === 'ar' ? 'شروط الخدمة' : 'Terms of Service'}
          </Link>
          &nbsp;&nbsp;
          <Link href="../../ContactUs" color="inherit" className="animated-link">
             {language === 'ar' ? 'إتصل بنا' : 'Contact us'}
          </Link>
        </div>
      </Box></Box></Box>
        </div>
        </LoadingProvider>
      </MuiThemeProvider>
    </CacheProvider>
  );
};

export default App;
