import React, { Component } from "react";
import { Box } from "@mui/material";

class AdComponentBanner extends Component {
  componentDidMount() {
    // Create the static BidVertiser script
    const staticScript = document.createElement("script");
    staticScript.type = "text/javascript";
    staticScript.setAttribute("data-cfasync", "false");
    staticScript.src = "//bdv.bidvertiser.com/BidVertiser.dbm?pid=937712&bid=2098958";
    
    // Append the script to the div
    const adContainer = document.getElementById("bidvertiser-container");
    if (adContainer) {
      adContainer.appendChild(staticScript);
    }
  }

  componentWillUnmount() {
    // Cleanup when component unmounts
    const adContainer = document.getElementById("bidvertiser-container");
    if (adContainer) {
      adContainer.innerHTML = "";
    }
  }

  render() {
    return  (
      <Box width={"100%"} alignItems={"center"} style={{ textAlign: "center", marginTop: "20px" }}>
        <div id="bidvertiser-container"></div>
      </Box>
    );
  }
}

export default AdComponentBanner;
