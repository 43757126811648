import { useEffect } from "react";
import { Box } from "@mui/material";

function AdComponent() {
  useEffect(() => {
    // Ensure the DOM is ready
    const adContainer = document.getElementById("ntv_2098958");
    if (!adContainer) return; // Exit if the div is missing

    // Create the script tag
    const script = document.createElement("script");
    script.type = "text/javascript";

    // Define the ad script parameters
    script.innerHTML = `
      (function(d) {
        var params = {
          bvwidgetid: "ntv_2098958",
          bvlinksownid: 2098958,
          rows: 1,
          cols: 6,
          textpos: "below",
          imagewidth: 150,
          mobilecols: 2,
          cb: (new Date()).getTime()
        };
        params.bvwidgetid = "ntv_2098958" + params.cb;
        
        // Check if the div exists before proceeding
        var adDiv = d.getElementById("ntv_2098958");
        if (!adDiv) return;

        adDiv.id = params.bvwidgetid;
        var qs = Object.keys(params)
          .reduce(function(a, k){ 
            a.push(k + '=' + encodeURIComponent(params[k])); 
            return a;
          }, []).join(String.fromCharCode(38));
        
        var s = d.createElement('script'); 
        s.type = 'text/javascript';
        s.async = true;
        var p = 'https:' == document.location.protocol ? 'https' : 'http';
        s.src = p + "://cdn.hyperpromote.com/bidvertiser/tags/active/bdvws.js?" + qs;
        d.getElementById(params.bvwidgetid)?.appendChild(s);
      })(document);
    `;

    // Append the script to the existing div
    adContainer.appendChild(script);

    // Cleanup on unmount to avoid memory leaks
    return () => {
      if (adContainer) adContainer.innerHTML = "";
    };
  }, []);

  return  (
    <Box width={"100%"} alignItems={"center"} style={{ textAlign: "center", marginTop: "20px" }}>
      <div id="ntv_2098958"></div>
    </Box>
  );
}

export default AdComponent;
