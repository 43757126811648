import React, { useState, useEffect } from "react";
import { Box, Button, TextField, Typography, Paper, CircularProgress } from "@mui/material";
import axios from "axios";
import { DownloadOutlined } from "@mui/icons-material";
import { useLanguage } from './LanguageContext';
import keywordsData from './keywords.json';
import { Helmet } from 'react-helmet';
import useSweetAlert from './SweetAlert';
import { useTheme } from './ThemeContext';

const BackgroundReplacer = () => {
  const { language } = useLanguage();
  const { showAlert } = useSweetAlert();
  const { theme } = useTheme();
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedBackGround, setSelectedBackGround] = useState(null);
  const [preview, setPreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const [randomNumber, setRandomNumber] = useState(null);
  const [selectedBg, setSelectedBg] = useState(null);
  const [backgrounds, setBackgrounds] = useState([]);
  const [error, setError] = useState("");
  const [selfieUrl, setSelfieUrl] = useState(null);
  const [priviewUrl, setPriviewSelfieUrl] = useState(null);
  const BACKGROUND_URL = "https://samrt-loader.com/backgrounds";
  const CAPTURE_URL = "https://samrt-loader.com/capture_selfie";
  const generateRandomNumber = () => {
    setRandomNumber(Math.floor(Math.random() * 100000000) + 1);
  };

  const isLight = theme === 'light' ? true : false

  const SEO = ({ title, description, keywords }) => (
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
          <meta name="keywords" content={keywords} />
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "WebSite",
                "name": "${title}",
                "url": "https://samrt-loader.com"
              }
            `}
          </script>
        </Helmet>
  );

  const handleFileChange = (event) => {
    setPreview(null);
    setSelectedImage(null);
    setSelfieUrl(null);
    setPriviewSelfieUrl(null);
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file);
      setPreview(URL.createObjectURL(file));
    }
  };

  const handleBackgroundUpload = (event) => {
    setSelectedBackGround(null);
    const file = event.target.files[0];
    if (file) {
      setSelectedBackGround(file);
    }
  };

  useEffect(() => {
    axios.get(BACKGROUND_URL).then(response => {
      setBackgrounds(response.data.backgrounds);
    }).catch(error => console.error("Error fetching backgrounds:", error));
  }, []);
  
  const handleDrop = (event) => {
    setPreview(null);
    setSelectedImage(null);
    setSelfieUrl(null);
    setPriviewSelfieUrl(null);
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      setSelectedImage(file);
      setPreview(URL.createObjectURL(file));
    }
  };

  const handleDragOver = (event) => {
    setPreview(null);
    setSelectedImage(null);
    setSelfieUrl(null);
    setPriviewSelfieUrl(null);
    event.preventDefault();
  };

  const captureSelfie = async () => {
    if (!selectedImage) {
        showAlert({
            text: language === 'ar' ? 'قم بإختيار صورة للتعديل اولا !' : 'Add a photo first !',
            icon: 'error',
            position: language === 'ar' ? 'top-end' : 'top-start'
        });
        return;
    }

    if (!selectedBg) {
      if(selectedBackGround){

      } else {
        showAlert({
          text: language === 'ar' ? 'قم بإختيار خلفية من الأعلى اولا !' : 'Please select or add background for replace from the top!',
          icon: 'error',
          position: language === 'ar' ? 'top-end' : 'top-start'
        });
        return;
      }
    }

    try {
        setSelfieUrl(null);
        setPriviewSelfieUrl(null);
        setLoading(true);

        // Create FormData to send the image file
        const formData = new FormData();
        formData.append("file", selectedImage);

        if (selectedBackGround) {
            formData.append("background_file", selectedBackGround);
        }
        formData.append("background", selectedBg);

        const response = await axios.post(CAPTURE_URL, formData, {
            headers: { "Content-Type": "multipart/form-data" }
        });

        setSelfieUrl(response.data.image_url);
        setPriviewSelfieUrl(response.data.priview_url);
        generateRandomNumber();
        setError("");
    } catch (error) {
        setError("Network issue, please try again...");
        console.error("Error capturing selfie:", error);
    } finally {
        setSelectedBackGround(null);
        setLoading(false);
    }
};

  

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
    >
    <SEO 
        title={language === 'ar' ? keywordsData.title_background_replacer_ar : keywordsData.title_background_replacer_en}
        description={language === 'ar' ? keywordsData.description_ar : keywordsData.description_en}
        keywords={keywordsData.keywords.join(', ')}
    />
      <Paper
        elevation={3}
        sx={{
          border: "2px dashed #aaa",
          padding: 2,
          width: 300,
          textAlign: "center",
          cursor: "pointer",
          mb: 2,
          bgcolor: isLight ? "white" : "black",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          "&:hover": { borderColor: "#007bff" },
        }}
        onClick={() => document.getElementById("fileInput").click()}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
      >
        {preview ? (
            <div>
            {priviewUrl ? (
                <Box
                component="img"
                src={priviewUrl}
                alt="Processed"
                sx={{ maxWidth: "100%", maxHeight: 250, borderRadius: 1, mt: 1 }}
                />
            ) :(
                <Box
                component="img"
                src={preview}
                alt="Preview"
                sx={{ maxWidth: "100%", maxHeight: 350, borderRadius: 1 }}
              />
            )}
            </div>
        ) : (
          <Typography variant="body1"  style={{height:300 ,paddingTop:120}}>{language === 'ar' ? 'إختر أو اسحب وأسقط لتحميل الصورة' : 'Click or Drag & Drop to Upload Image'}</Typography>
        )}
        <input
          id="fileInput"
          type="file"
          accept="image/*"
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
      </Paper>
      <div style={styles.backgroundGallery}>
        {backgrounds.map(bg => (
          <img 
            key={bg.name} 
            src={bg.url} 
            alt={bg.name} 
            style={{ ...styles.bgImage, border: selectedBg === bg.name ? "3px solid blue" : "none" }}
            onClick={() => setSelectedBg(bg.name)}
          />
        ))}
      </div>
      <Box>
        <h4>{language === 'ar' ? 'أو أضف خلفية' : 'Or add background'}</h4>
        <input type="file" accept="image/*" onChange={handleBackgroundUpload} />
      </Box>
      <p style={{ color: "red" }}>{error}</p>
      {selfieUrl && (
        <div style={styles.resultContainer}>
          <Box mt={1} width={"100%"}>
            <Button
              variant="contained"
              color="success"
              startIcon={<DownloadOutlined />}
              style={styles.downloadButton}
              href={selfieUrl}
              download={`AI_Selfie_${randomNumber}.jpg`}
            >
              {language === 'ar' ? 'تحميل HD' : 'Download HD'}
            </Button>
          </Box>
        </div>
      )}
     <Button variant="contained" color="primary" onClick={captureSelfie} disabled={loading}>
        {loading ? <CircularProgress size={24} /> : language === 'ar' ? 'تغيير الخلفية' : 'Replace Background'}
      </Button>

    </Box>
  );
};

const styles = {
    container: { textAlign: "center", padding: "20px", fontFamily: "Arial, sans-serif" },
    title: { color: "#333", fontSize: "2rem" },
    backgroundGallery: { display: "flex", overflowX: "auto", gap: "10px", padding: "10px", whiteSpace: "nowrap",marginTop:5,width: "-webkit-fill-available" },
    bgImage: { width: "100px", height: "100px", cursor: "pointer", borderRadius: "8px" },
    videoContainer: { display: "flex", justifyContent: "center", marginBottom: "20px" },
    videoFeed: { width: "100%", borderRadius: "10px" },
    captureButton: { fontSize: "1rem", padding: "10px 20px", marginTop: "10px" },
    downloadButton: { fontSize: "1rem", padding: "10px 20px", marginTop: "5px", marginBottom: 20 },
    resultContainer: { marginTop: "20px" },
    image: { width: "100%", borderRadius: "10px", marginTop: "10px", display: "block" },
  };

export default BackgroundReplacer;